import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

const RussianFood = () => {
  // State to handle the visibility of the popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <div className="menu-container">
      <header className="menu-header">
        <div className="menu-div">
          <a href="/">
            <img className="logo" src="img/nb.png" alt="Logo" />
          </a>
        </div>
        <h1>Меню A la carte</h1>
      </header>

      <div className="menu-sections">
        {/* appetizer Section */}
        <div className="menu-section">
          <h2 className="russian">Закуски и салаты</h2>
          <div className="menu-item">
            <span>Фалафель</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">
            Жареный молотый нут, петрушка, кориандр, чеснок, зира, лайм.
            Подаётся с соусом Тахини
          </p>

          <div className="menu-item">
            <span>Татаки из тунца</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Тунец для суши, сальса из свежего авокадо, кунжут, имбирь. Подаётся
            с кунжутом и соевым соусом
          </p>

          <div className="menu-item">
            <span>Салат из трех видов фасоли</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Три вида фасоли (нут, фасоль, сельдерей и фасоль Каннеллини),
            смешанные с зеленью
          </p>

          <div className="menu-item">
            <span>Салат из креветок и авокадо</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Креветки, салат, лук, авокадо, смешанные с рубленой петрушкой.
            Подаётся с заправкой из оливкового масла и лайма
          </p>

          <div className="menu-item">
            <span>Тайский салат с говядиной (острый)</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Обжаренная говядина подается с красным луком, огурцом, помидором,
            кинзой, листьями свежей мяты, перцем чили, сахаром и орехово-соевым
            соусом
          </p>

          <div className="menu-item">
            <span>Суп Том Ям (Тайский кисло-острый суп)</span>
            <span>Rs 2200/=</span>
          </div>
          <p className="menu-des">
            Креветки с лемонграссом, кокосовое молоко, листья лайма, имбирь,
            паста из креветок, сахар, рыбный соус, сок лайма и томатное пюре
          </p>

          <div className="menu-item">
            <span>Грибной крем-суп</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            Грибы, приготовленные с маслом, чесноком, луком и мукой в соусе на
            молочной основе
          </p>
        </div>

        {/* c Section */}
        <div className="menu-section">
          <h2 className="russian">Основные блюда</h2>
          <div className="menu-item">
            <span>Сёрф-энд-Тёрф</span>
            <span>Rs 5500/=</span>
          </div>
          <p className="menu-des">
            Стейк из говядины и креветки на гриле, картофельное пюре, чеснок,
            лук и обжаренные свежие деревенские овощи. Подаётся со
            сливочно-чесночным соусом
          </p>

          <div className="menu-item">
            <span>Курица по-французски</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Курица, обжаренная в яйце и муке, приготовленная с белым вином,
            соусом из лимона и петрушки. Подаётся с чесноком, луком,
            деревенскими овощами и картофельным пюре
          </p>

          <div className="menu-item">
            <span>Ризотто с грибами</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Круглозерный рис, свежие грибы, курица, белое вино, свежие травы и
            сыр пармезан
          </p>

          <div className="menu-item">
            <span>Паста Фрутти ди Маре</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Спагетти с маленькими моллюсками, осьминогом, кальмарами и
            креветками, приготовленные в домашнем соусе Аррабиата. Подаётся с
            сыром пармезан
          </p>

          <div className="menu-item">
            <span>Стейк из свежего желтоперого тунца</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Филе тунца, приготовленное на гриле. Подаётся с молодым картофелем,
            приправленным травами и свежим салатом
          </p>

          <div className="menu-item">
            <span>Классическое Британское блюдо Фиш-энд-чипс</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Обжаренное в кляре филе свежей рыбы-луциана. Подаётся с домашним
            картофелем фри и домашним соусом тартар
          </p>

          <div className="menu-item">
            <span>Сливочно-чесночная свиная отбивная</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Маринованная свиная отбивная, приготовленная в сливочно-чесночном
            соусе с пармезаном. Подаётся с белым рисом, настоянным на чесноке и
            базилике, или со сливочно-грибными спагетти
          </p>

          <div className="menu-item">
            <span>Жареная баранья отбивная</span>
            <span>Rs 6500/=</span>
          </div>
          <p className="menu-des">
            Нежная маринованная отбивная из баранины, приготовленная на гриле и
            уложенная на мятный соус. Подается с картофельным пюре с чесноком и
            луком и пассерованными свежими овощами
          </p>

          <div className="menu-item">
            <span>Равиоли со шпинатом и рикоттой</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Равиоли с начинкой из шпината и сыра Рикотта, обжаренные с луком и
            итальянскими травами, слегка тушеные в традиционном соусе Маринара
            или сливочном соусе
          </p>

          <div className="menu-item">
            <span>Трёхэтажный бургер</span>
            <span>Rs 3200/=</span>
          </div>
          <p className="menu-des">
            Тройной вкус нашей шриланкийской говядины, лука, соленых огурцов,
            помидор, салата айсберг, кетчупа, горчицы и сыра чеддер. Все это
            внутри домашней мягкой поджаренной булочки и подается с домашним
            картофелем фри
          </p>

          <div className="menu-item">
            <span>Хрустящий куриный бургер</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Хорошо приправленная хрустящая курица без костей, подается на
            поджаренной булочке с халапеньо, салатом из капусты с
            медово-горчичной заправкой. Подаётся с домашним картофелем фри
          </p>

          <div className="menu-item">
            <span>Свежая рыба-провидец (королевская макрель) на гриле</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Филе рыбы-провидец, маленький молодой картофель и свежий салат.
            Подаётся со сливочно-чесночным соусом или c соусом из каперсов и
            лимона
          </p>

          <div className="menu-item">
            <span>Кускус с курицей и овощами</span>
            <span>Rs 2400/=</span>
          </div>
          <p className="menu-des">
            Кускус, обжаренный со свежими овощами, курицей и яйцом
          </p>

          <div className="menu-item">
            <span>Паста Паппарделле с соусом Альфредо</span>
            <span>Rs 3400/=</span>
          </div>
          <p className="menu-des">
            Паста с обжаренной куриной грудкой и грибами. Подаются в соусе
            Альфредо и посыпаны свежей нарезанной приправой
          </p>

          <div className="menu-item">
            <span>Хрустящие креветки и овощи темпура</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Свежие, приправленные, обжаренные в кляре во фритюре королевские
            креветки и свежие деревенские овощи. Подаётся с темпура соусом,
            рукколой и салатом из помидоров черри
          </p>

          <div className="menu-item">
            <span>
              Наше фирменное блюдо с шипящими свежими морепродуктами из
              Индийского океана
            </span>
            <span>Rs 35000/=</span>
          </div>
          <p className="menu-des">
            Хорошо приправленные свежие морепродукты на гриле: целая кефаль,
            один молодой осьминог, две гигантские креветки, два лобстера, два
            краба, восемь тигровых креветок, восемь кальмаров, десять моллюсков
            и десять мидий. Подаются с белым рисом, настоянным на чесноке и
            базилике, с обжаренным водяным шпинатом или отварные овощи с
            обжаренным луком и чесноком, картофельное пюре подаётся со свежим
            салатом (От нас мы Вам бесплатно подарим бутылку белого или красного
            вина)
          </p>

          <div className="menu-item">
            <span>Кальмары с лимоном и чесноком</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Свежие кальмары, приправленные соком лайма, чесноком, луком и
            имбирём и приготовленные на гриле. Подаётся с картофельным пюре и
            свежим салатом
          </p>
        </div>
      </div>

      <div className="menu-sections">
        {/* Rice and Curry Section */}
        <div className="menu-section">
          <h2 className="russian">Шри-ланкийский рис и карри</h2>
          <div className="menu-item">
            <span>Овощной карри</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            Свежие овощи, приготовленные в кокосовом молоке с шри-ланкийской
            приправой карри. Подаётся с белым рисом, кокосовым самболом,
            манговым чатни, пападамом и жареным чили
          </p>

          <div className="menu-item">
            <span>Карри из лагунного краба</span>
            <span>Rs 3600/=</span>
          </div>
          <p className="menu-des">
            Свежий краб из лагуны озера Коггала, приготовленный с
            шри-ланкийскими специями, кокосовым молоком и специальной приправой
            карри из Индии. Подаётся с белым рисом, кокосовым самболом, манговым
            чатни и пападамом или в индийском стиле паратха (с индийскими
            лепешками)
          </p>

          <div className="menu-item">
            <span>Карри из лагунных креветок</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Свежие королевские креветки из лагуны озера Коггала, приготовленные
            с шри-ланкийскими специями, кокосовым молоком и приправой карри.
            Подаётся с белым рисом, кокосовым самболом, манговым чатни и
            пападамом или в индийском стиле паратха (с индийскими лепешками)
          </p>

          <div className="menu-item">
            <span>Ананасовый карри</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des">
            Ананас, приготовленный в кокосовом молоке и манговом чатни, пападам,
            кокосовый самбол и салат из горькой тыквы. Подаётся с рисом или
            паратха (индийские лепешки)
          </p>

          <div className="menu-item">
            <span>Карри из осьминога и кокоса</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Свежий осьминог, приготовленный в кокосовом молоке с шри-ланкийской
            приправой карри, манговый чатни, пападам, кокосовый самбол и салат
            из горькой тыквы. Подаётся с рисом или паратха (индийские лепешки)
          </p>

          <div className="menu-item">
            <span>Дал карри (чечевица)</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            Чечевица, приготовленная с луком, листьями карри, тмином и приправой
            карри из семян горчицы. Подается с манговым чатни, пападамом,
            салатом из горькой тыквы, рисом и кокосовым самболом
          </p>

          <div className="menu-item">
            <span>Карри с креветками</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Свежие креветки, приготовленные в кокосовом молоке и шри-ланкийском
            порошке карри, поль самбол, чатни из манго, пападам, подаются с
            рисом или парата (индийские лепешки)
          </p>

          <div className="menu-item">
            <span>Рыбный карри</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Свежая рыба, приготовленная в кокосовом молоке и шри-ланкийской
            приправе карри. Подается с кокосовым самболом, манговым чатни,
            пападамом, салатом из горькой тыквы и рисом или паратой (индийские
            лепешки)
          </p>

          <div className="menu-item">
            <span>*Кокосовый самбол</span>
            <span></span>
          </div>
          <p className="menu-des">
            (Салат из тертого кокоса с соком лайма, луком и перцем чили)
          </p>

          <div className="menu-item">
            <span>*Пападам</span>
            <span></span>
          </div>
          <p className="menu-des">
            (Хрустящие жареные сухарики из чечевичной муки)
          </p>

          <div className="menu-item">
            <span>*Манговый чатни*Манговый чатни</span>
            <span></span>
          </div>
          <p className="menu-des">(Смесь из манго, приправ, сахара и уксуса)</p>

          <div className="menu-item">
            <span>*Салат из горькой тыквы</span>
            <span></span>
          </div>
          <p className="menu-des">(Горькая тыква, лук, помидор, лайм)</p>
        </div>

        {/* Side Dish Section */}
        <div className="menu-section">
          <h2 className="russian">Гарниры</h2>
          <div className="menu-item">
            <span>Овощи отварные или на гриле</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Домашний картофель фри</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Пропаренный рис (маленький)</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Чапати и Парата (индийские лепешки)</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Картофельное пюре</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* kids Section */}
        <div className="menu-section">
          <h2 className="russian">Детское меню</h2>
          <div className="menu-item">
            <span>Куриный баскет</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des">
            (Куриная голень, панированная и обжаренная во фритюре. Подаётся с
            картофелем фри)
          </p>

          <div className="menu-item">
            <span>Сырная куриная паста</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">(Паста со сливочным соусом и курицей)</p>

          <div className="menu-item">
            <span>Маленький сырный бургер</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            Говяжий фарш, приготовленный с зеленью, подается с картофелем фри
          </p>

          <div className="menu-item">
            <span>Рыбные палочки</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>
        </div>

        {/* dessert Section */}
        <div className="menu-section">
          <h2 className="russian">Дессерты</h2>
          <div className="menu-item">
            <span>Павлова</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            (Яичный белок, ванильный экстракт, сахар, сливки, шоколад)
          </p>

          <div className="menu-item">
            <span>Жареный банан с корицей</span>
            <span>Rs 1650/=</span>
          </div>
          <p className="menu-des">(Банан, мёд, масло, корица)</p>

          <div className="menu-item">
            <span>Ананас в кляре с кокосом</span>
            <span>Rs 1650/=</span>
          </div>
          <p className="menu-des">(Ананас, Яйцо, Мука, Кокос, Молоко)</p>

          <div className="menu-item">
            <span>Три вида мороженого (Создайте своё собственное)</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            (Ванильное, Шоколадное, Клубничное, Манговое, Фруктовое с орехами,
            Ириска)
          </p>
        </div>

        <div className="notice" style={{ textAlign: "center" }}>
          <h3>ВЫБОР МОРЕПРОДУКТОВ НА ПЛЯЖЕ</h3>
          <p>Пожалуйста спросите своего официанта</p>
          <p>-Цена дня-</p>
          <p>
            Лобстер, Краб, Гигантские креветки, Целая рыба, Осьминог, Кальмары
          </p>
          <p>Подаётся с картофелем фри и салатом или рисом</p>
        </div>

        {/* Button to trigger the popup */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button onClick={togglePopup} className="popup-button">
            Follow US
          </button>
        </div>

        {/* Popup content */}
        {isPopupVisible && (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                className="popup-links-container"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  fontSize: "40px",
                  padding: "35px",
                }}
              >
                <a
                  href="https://www.facebook.com/Nbunawatuna/"
                  target="_blank"
                  className="popup-link-button popup-link-facebook"
                >
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    className="popup-follow-icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/neptune_bay/"
                  target="_blank"
                  className="popup-link-button popup-link-instagram"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="popup-follow-icon"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@neptunebay"
                  target="_blank"
                  className="popup-link-button popup-link-tiktok"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    className="popup-follow-icon"
                  />
                </a>
              </div>
              <p>Сканируй здесь, чтобы связаться с нами в Инстаграм</p>
              <button onClick={togglePopup} className="close-button">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RussianFood;
