import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import About from "./pages/About";
import Room from "./pages/Room";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Experience from "./pages/Experience";
import QR from "./pages/QR";
import EnglishMenu from "./pages/EnglishMenu";
import RussianMenu from "./pages/RussianMenu";
import RussianDrink from "./pages/RussianDrink";
import EnglishDrink from "./pages/EnglishDrink";
import RussianFood from "./pages/RussianFood";
import EnglishFood from "./pages/EnglishFood";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/room" element={<Room />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/experience" element={<Experience />} />

          <Route path="/qr-menu" element={<QR />} />
          
          <Route path="/qr-menu-english" element={<EnglishMenu />} />
          <Route path="/qr-menu-english-food" element={<EnglishFood />} />
          <Route path="/qr-menu-english-drink" element={<EnglishDrink />} />

          <Route path="/qr-menu-russian" element={<RussianMenu />} />
          <Route path="/qr-menu-russian-food" element={<RussianFood />} />
          <Route path="/qr-menu-russian-drink" element={<RussianDrink />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
