import React from "react";
// import { useTranslation } from 'react-i18next';

const RussianDrink = () => {
  // const { t } = useTranslation();

  return (
    <div className="menu-container">
      <header className="menu-header">
        <div className="menu-div">
          <a href="/">
            <img className="logo" src="img/nb.png" alt="Logo" />
          </a>
        </div>
        <h1>МЕНЮ</h1>
      </header>

      <div className="menu-sections">
        {/* CLASSIC COCKTAILS Section */}
        <div className="menu-section">
          <h2 className="russian">КЛАССИЧЕСКИЕ КОКТЕЙЛИ</h2>
          <div className="menu-item">
            <span>ОЛД ФЭШН</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">БУРБОН, КУСОЧЕК САХАРА, БИТТЕР АНГОСТУРА</p>

          <div className="menu-item">
            <span>НЕГРОНИ</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">ДЖИН, МАРТИНИ РОССО, ЛИКЁР КАМПАРИ</p>

          <div className="menu-item">
            <span>СУХОЙ МАРТИНИ</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">ДЖИН, СУХОЙ ВЕРМУТ</p>

          <div className="menu-item">
            <span>СИНГАПУРСКИЙ СЛИНГ</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            ДЖИН, АПЕЛЬСИНОВЫЙ ЛИКЁР, ВИШНЁВЫЙ БРЕНДИ, АНАНАСОВЫЙ СОК, ЛАЙМ,
            СОДОВАЯ
          </p>

          <div className="menu-item">
            <span>КУБА ЛИБРЕ</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">БЕЛЫЙ РОМ, КОЛА, СОК ЛАЙМА, САХАРНЫЙ СИРОП</p>

          <div className="menu-item">
            <span>ЗАМОРОЖЕННЫЙ ДАЙКИРИ</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">БЕЛЫЙ РОМ, СОК ЛАЙМА, САХАРНЫЙ СИРОП</p>

          <div className="menu-item">
            <span>ПЫЛАЮЩИЙ ЛАМБОРГИНИ </span>
            <span>Rs 5000/=</span>
          </div>
          <p className="menu-des">
            БЕЙЛИС, КОФЕЙНЫЙ ЛИКЁР, САМБУКА, БЛЮ КЮРАСАО
          </p>

          <div className="menu-item">
            <span>МАРГАРИТА</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">ТЕКИЛА, ЛИКЁР ТРИПЛ СЕК, СОК ЛАЙМА</p>

          <div className="menu-item">
            <span>ЛОНГ АЙЛЕНД</span>
            <span>Rs 4000/=</span>
          </div>
          <p className="menu-des">
            РОМ, ВОДКА, ДЖИН, ТЕКИЛА, ЛИКЁР ТРИПЛ СЕК, СОК ЛАЙМА, КОЛА
          </p>

          <div className="menu-item">
            <span>ПИНА КОЛАДА</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            БЕЛЫЙ РОМ, МАЛИБУ, КОКОСОВЫЕ СЛИВКИ, АНАНАСОВЫЙ СОК
          </p>

          <div className="menu-item">
            <span>БУЛЛФРОГ</span>
            <span>Rs 4500/=</span>
          </div>
          <p className="menu-des">
            ВОДКА, ДЖИН, РОМ, ТЕКИЛА, БЛЮ КЮРАСАО, СОК ЛАЙМА, РЕДБУЛЛ
          </p>

          <div className="menu-item">
            <span>ТЕКИЛА САНРАЙЗ</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">ТЕКИЛА, АПЕЛЬСИНОВЫЙ СОК, СИРОП ГРЕНАДИН</p>

          <div className="menu-item">
            <span>КРЁСТНЫЙ ОТЕЦ </span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">ВИСКИ СКОТЧ И АМАРЕТТО</p>

          <div className="menu-item">
            <span>МОХИТО</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            БЕЛЫЙ РОМ, ЛИСТЬЯ МЯТЫ, СОК ЛАЙМА, СОДОВАЯ (ВКУСЫ НА ВЫБОР)
          </p>

          <div className="menu-item">
            <span>АПЕРОЛЬ ШПРИЦ</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">ИГРИСТОЕ ВИНО, АПЕРОЛЬ, СОДОВАЯ</p>
        </div>

        {/* SIGNATURE COCKTAILS Section */}
        <div className="menu-section">
          <h2 className="russian">ФИРМЕННЫЕ КОКТЕЙЛИ</h2>
          <div className="menu-item">
            <span>АРАК МАРАКУЙЯ </span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            ОЛД АРАК, МАРАКУЙЯ, СОК ЛАЙМА, САХАРНЫЙ СИРОП
          </p>

          <div className="menu-item">
            <span>АРАК SHOWER</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            АРАК, ЯИЧНЫЙ БЕЛОК, СОК ЛАЙМА, САХАРНЫЙ СИРОП
          </p>

          <div className="menu-item">
            <span>БЕРТИ</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            ДЖИН, СИРОП ИЗ БУЗИНЫ, ЛИСТЬЯ МЯТЫ, СОК ЛАЙМА
          </p>

          <div className="menu-item">
            <span>БАЗИЛИКОВЫЙ СМЭШ</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            ДЖИН, СВЕЖИЙ БАЗИЛИК, СОК ЛАЙМА, САХАРНЫЙ СИРОП
          </p>

          <div className="menu-item">
            <span>РОЗОВАЯ ПАНТЕРА</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            ВОДКА, АПЕЛЬСИНОВЫЙ СОК, АРБУЗ, СОК ЛАЙМА, САХАРНЫЙ СИРОП
          </p>

          <div className="menu-item">
            <span>SIYABALANESE</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            КРАСНЫЙ РОМ, ВИШНЁВЫЙ БРЕНДИ, ТАМАРИНД, СОК МАРАКУЙИ
          </p>
        </div>
      </div>

      <div className="menu-sections">
        {/* NON-ALCOHOLIC COCKTAILS Section */}
        <div className="menu-section">
          <h2 className="russian">БЕЗАЛКОГОЛЬНЫЕ КОКТЕЙЛИ</h2>
          <div className="menu-item">
            <span>ЛИМОНАД ИМБИРЬ-ЛИЧИ</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">СВЕЖИЙ ИМБИРЬ, ЛИЧИ, СОК ЛАЙМА, ЛИМОНАД</p>

          <div className="menu-item">
            <span>D-MOULAY</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">
            ЛЕМОНГРАСС, СОК ЛАЙМА, САХАРНЫЙ СИРОП, СОДОВАЯ
          </p>

          <div className="menu-item">
            <span>КИВИ И ОГУРЕЦ</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">ОГУРЕЦ, СИРОП КИВИ, СОК ЛАЙМА, СОДОВАЯ</p>

          <div className="menu-item">
            <span>СОДОВАЯ ТУТТИ ФРУТТИ</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">
            КЛЮКВЕННЫЙ СОК, ИМБИРЬ, АПЕЛЬСИНОВЫЙ СОК, СОДОВАЯ
          </p>

          <div className="menu-item">
            <span>БЕЗАЛКОГОЛЬНЫЙ МОХИТО</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">
            ЛИСТЬЯ МЯТЫ, СОК ЛАЙМА, САХАРНЫЙ СИРОП, СПРАЙТ (ВКУСЫ НА ВЫБОР)
          </p>

          <div className="menu-item">
            <span>БЕЗАЛКОГОЛЬНАЯ ПИНА КОЛАДА</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">АНАНАСОВЫЙ СОК, КОКОСОВЫЙ СИРОП</p>
        </div>

        {/* BEER Section */}
        <div className="menu-section">
          <h2 className="russian">ПИВО</h2>
          <div className="menu-item">
            <span>LION ЛАГЕР 625МЛ.</span>
            <span>Rs 1100/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>LION ЛАГЕР 500МЛ</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>CARLSBERG 500МЛ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>HEINEKEN 500МЛ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>LION ICE 330МЛ</span>
            <span>Rs 800/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>TIGER 550МЛ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>SOMERSBY ЯБЛОКО</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>SOMERSBY ЕЖЕВИКА</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* SOFT DRINKS Section */}
        <div className="menu-section">
          <h2 className="russian">БЕЗАЛКОГОЛЬНЫЕ НАПИТКИ</h2>
          <div className="menu-item">
            <span>РЕДБУЛЛ</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ДИЕТИЧЕСКАЯ КОЛА</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>КОКА-КОЛА</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>СПРАЙТ </span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ФАНТА</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ТОНИК</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ИМБИРНЫЙ ЭЛЬ</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ИМБИРНОЕ ПИВО</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>СОДОВАЯ</span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВОДА</span>
            <span>Rs 400/=</span>
          </div>
          <p className="menu-des"></p>
        </div>

        {/* FRESH JUICES Section */}
        <div className="menu-section">
          <h2 className="russian">СВЕЖЕВЫЖАТЫЕ СОКИ</h2>
          <div className="menu-item">
            <span>АНАНАС</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>МАНГО</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ПАПАЙЯ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>АРБУЗ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>МУЛЬТИФРУКТ</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ЛАЙМ</span>
            <span>Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>АВОКАДО</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>КОРОЛЕВСКИЙ КОКОС </span>
            <span>Rs 500/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* MILKSHAKES Section */}
        <div className="menu-section">
          <h2 className="russian">МОЛОЧНЫЕ КОКТЕЙЛИ</h2>
          <div className="menu-item">
            <span>БАНАНОВЫЙ</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>МАНГО</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>КЛУБНИЧНЫЙ</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ШОКОЛАДНЫЙ</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>АНАНАСОВЫЙ</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВАНИЛЬНЫЙ</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>
        </div>

        {/* WHITE WINE Section */}
        <div className="menu-section">
          <h2 className="russian">БЕЛОЕ ВИНО</h2>
          <div className="menu-item">
            <span>EL ZORRO EL BLANCO</span>
            <span>БОКАЛ Rs 1500/=, БУТЫЛКА RS 7500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>BANROCK STATION CHARDONNAY</span>
            <span>БОКАЛ Rs 2800/=, БУТЫЛКА RS 14000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>THE BEACH HOUSE SAUVIGNON</span>
            <span>БОКАЛ Rs 3000/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>TALL HORSE CHARDONNAY</span>
            <span>БОКАЛ Rs 3000/=, БУТЫЛКА RS 12000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>CONO SUR TOCORNAL SAUVIGNON BLANC</span>
            <span>БОКАЛ Rs 2800/=, БУТЫЛКА RS 14000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>CONO SUR TOCORNAL CHARDONNAY</span>
            <span>БОКАЛ Rs 2800/=, БУТЫЛКА RS 14000/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* RED WINE Section */}
        <div className="menu-section">
          <h2 className="russian">КРАСНОЕ ВИНО</h2>
          <div className="menu-item">
            <span>EL ZORRO EL ROJO</span>
            <span>БОКАЛ Rs 2400/=, БУТЫЛКА RS 12000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>TALL HORSE PINOTAGE ROSE</span>
            <span>БОКАЛ Rs 3000/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>CONO SUR TOCORNAL MERLOT </span>
            <span>БОКАЛ Rs 2800/=, БУТЫЛКА RS 14500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>CONO SUR TOCORNAL CABERNET SAUVIGNON</span>
            <span>БОКАЛ Rs 3000/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>
        </div>

        {/* LOCAL ALCOHOL Section */}
        <div className="menu-section">
          <h2 className="russian">МЕСТНЫЙ АЛКОГОЛЬ</h2>
          <div className="menu-item">
            <span>ВОДКА</span>
            <span>50МЛ Rs 1200/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ДЖИН</span>
            <span>50МЛ Rs 1600/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>БЕЛЫЙ РОМ</span>
            <span>50МЛ Rs 1500/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ТЁМНЫЙ РОМ</span>
            <span>50МЛ Rs 1500/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ТЕКИЛА</span>
            <span>50МЛ Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ОЛД АРАК</span>
            <span>50МЛ Rs 1200/=, БУТЫЛКА RS 10000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>БРЕНДИ RITZ</span>
            <span>50МЛ Rs 1500/=, БУТЫЛКА RS 15000/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* IMPORTED ALCOHOL Section */}
        <div className="menu-section">
          <h2 className="russian">ИМПОРТНЫЙ АЛКОГОЛЬ</h2>
          <div className="menu-item">
            <span>ВИСКИ JACK DANIEL’S</span>
            <span>25МЛ Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВИСКИ BLACK LABEL</span>
            <span>25МЛ Rs 1700/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВИСКИ VAT 69</span>
            <span>25МЛ Rs 1200/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВИСКИ JAMESON</span>
            <span>25МЛ Rs 1300/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ДЖИН HENDRICK’S</span>
            <span>25МЛ Rs 1800/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ДЖИН BOMBAY SAPPHIRE</span>
            <span>25МЛ Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВОДКА ABSOLUTE </span>
            <span>25МЛ Rs 1600/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>ВОДКА SKYY</span>
            <span>25МЛ Rs 1700/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>
    </div>
  );
};

export default RussianDrink;
