import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Check if scroll event is being detected
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0); // Update state if the scroll position is greater than 0
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="sticky-header" >
      <div className={`header-area ${isScrolled ? 'scrolled' : ''}`}>
        <div className="main-header-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col d-flex flex-row justify-content-start">
                <div className="logo-img">
                  <a href="/">
                    <img className="im" src="img/nb.png" alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="col-center d-none d-lg-flex">
                <div className="main-menu">
                  <nav>
                    <ul className="navigation">
                      <li><a href="/">{t('navbar.home')}</a></li>
                      <li><a href="/room">{t('navbar.stay')}</a></li>
                      <li><a href="/about">{t('navbar.about')}</a></li>
                      <li><a href="#">{t('navbar.more')}</a>
                        <ul className="submenu">
                          <li><a href="/blog">{t('navbar.blog')}</a></li>
                          <li><a href="/experience">{t('navbar.experience')}</a></li>
                          <li><a href="/gallery">{t('navbar.gallery')}</a></li>
                        </ul>
                      </li>
                      <li><a href="/contact">{t('navbar.contact')}</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-right d-none d-lg-flex justify-content-end align-items-center">
                <div className="book-room">
                  <div className="social-links">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook-square"></i></a></li>
                      <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                      <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                    </ul>
                  </div>
                  <div className="language-switcher">
                    <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
                      <option value="en">English 🇬🇧</option>
                      <option value="fr">Français 🇫🇷</option>
                      <option value="ru">Russian 🇷🇺</option>
                      <option value="es">Español 🇪🇸</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-mobile d-lg-none">
                <div className="mobile-menu">
                  <button className="mobile-menu-btn" onClick={toggleMobileMenu}>
                    <span className="menu-icon">
                      <span className="menu-icon-bar"></span>
                      <span className="menu-icon-bar"></span>
                      <span className="menu-icon-bar"></span>
                    </span>
                  </button>
                </div>
              </div>
              {isMobileMenuOpen && (
                <div className="mobile-nav d-lg-none">
                  <ul className="mobile-navigation">
                    <li><a href="/">{t('navbar.home')}</a></li>
                    <li><a href="/room">{t('navbar.stay')}</a></li>
                    <li><a href="/about">{t('navbar.about')}</a></li>
                    <li><a href="#">{t('navbar.more')}</a>
                      <ul className="submenu">
                        <li><a href="/blog">{t('navbar.blog')}</a></li>
                        <li><a href="/experience">{t('navbar.experience')}</a></li>
                        <li><a href="/gallery">{t('navbar.gallery')}</a></li>
                      </ul>
                    </li>
                    <li><a href="/contact">{t('navbar.contact')}</a></li>
                    <li style={{marginLeft: '37%'}}>
                      <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
                        <option value="en">English 🇬🇧</option>
                        <option value="fr">Français 🇫🇷</option>
                        <option value="ru">Russian 🇷🇺</option>
                        <option value="es">Español 🇪🇸</option>
                      </select>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
