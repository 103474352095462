import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const images = [
  { image: process.env.PUBLIC_URL + "/img/banner/about_banner2.png" },
  { image: process.env.PUBLIC_URL + "/img/banner/about_banner4.png" },
  { image: process.env.PUBLIC_URL + "/img/banner/about_banner.png" },
  { image: process.env.PUBLIC_URL + "/img/banner/about_banner2.png" },
  { image: process.env.PUBLIC_URL + "/img/banner/about_banner2.png" },
  { image: process.env.PUBLIC_URL + "/img/instragram/1.png" },
  { image: process.env.PUBLIC_URL + "/img/instragram/2.png" },
  { image: process.env.PUBLIC_URL + "/img/instragram/3.png" },
];

Modal.setAppElement("#root");

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const { t } = useTranslation();

  const openModal = (img) => {
    setCurrentImage(img);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage("");
  };

  return (
    <>
      {/* <!-- bradcam_area_start --> */}
      <div class="bradcam_area breadcam_bg_3">
        <h3>{t("gallery.title")}</h3>
      </div>
      {/* <!-- bradcam_area_end --> */}

      {/* <!-- about_main_info_start --> */}
      <div class="about_main_info wow animate__animated animate__fadeInDown">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-md-12">
              <div class="single_about_info" style={{ textAlign: "center" }}>
                <p style={{ fontSize: "20px" }}> {t("gallery.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about_main_info_end --> */}

      <div
        style={{
          paddingTop: "50px",
          marginRight: "10px",
          marginLeft: "10px",
          marginBottom: "140px",
        }}
      >
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="10px" className="gallery">
            {images.map((img, i) => (
              <div key={i} className="masonry-item">
                <img
                  src={img.image}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                    cursor: "pointer",
                  }}
                  alt=""
                  onClick={() => openModal(img.image)}
                />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>

        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="modal-overlay"
        >
          <button onClick={closeModal} className="close-btn">
            ×
          </button>
          <img src={currentImage} alt="" className="modal-image" />
        </Modal>
      </div>
    </>
  );
};

export default Gallery;
