import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Experience = () => {

    const { t } = useTranslation();

    return(
        <>
        {/* <!-- bradcam_area_start --> */}
        <div class="bradcam_area breadcam_bg_4">
                <h3>{t('experience.title')}</h3>
            </div>
            {/* <!-- bradcam_area_end --> */}

            {/* <!-- about_main_info_start --> */}
            <div class="about_main_info wow animate__animated animate__fadeInDown">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-md-12">
                            <div class="single_about_info" style={{textAlign: 'center'}}>
                                <p style={{fontSize: '20px'}}> {t('experience.description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_main_info_end --> */}

            <section class="ftco-section">
                <div class="container">
                    <div class="row2 d-flex" style={{marginBottom: '40px', marginTop: '50px'}}>
                        <div class="col-md-4 d-flex wow slideInLeft" >
                            <div class="blog-entry align-self-stretch">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/galle-fort.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a >{t('experience.heading1')}</a></h3>
                                    <p>{t('experience.desc1')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInLeft" >
                            <div class="blog-entry align-self-stretch">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/diving.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a>{t('experience.heading2')}</a></h3>
                                    <p>{t('experience.desc2')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInLeft" >
                            <div class="blog-entry align-self-stretch">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/surfing.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a>{t('experience.heading3')}</a></h3>
                                    <p>{t('experience.desc3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row2 d-flex' style={{marginBottom: '40px', marginTop: '30px'}}>
                    <div className="col-md-4 d-flex wow slideInRight" >
                            <div className="blog-entry align-self-stretch">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/market1.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a>{t('experience.heading4')}</a></h3>
                                    <p>{t('experience.desc4')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInRight" >
                            <div class="">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/turtle.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class=""><a>{t('experience.heading5')}</a></h3>
                                    <p>{t('experience.desc5')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex wow slideInRight" >
                            <div className="blog-entry align-self-stretch">
                                <a class="block-20 rounded test"
                                    style={{ backgroundImage: 'url(img/beach.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a>{t('experience.heading6')}</a></h3>
                                    <p>{t('experience.desc6')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
        </>
    )
}

export default Experience;