import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useTranslation } from 'react-i18next';
import WOW from 'wowjs';
import 'animate.css';

const Home = () => {

    const { t } = useTranslation();

    const options = {
        items: 1,
        loop: false,
        nav: true,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ],
        dots: true,
        autoplay: false
    };

    const slides = [
        {
            bgClass: 'slider_bg_1'

        }
    ];

    useEffect(() => {
        new WOW.WOW({
            live: false // Disable reinitializing on dynamically added content
        }).init();
    }, []);

    return (
        <>
            {/* <!-- slider_area_start --> */}
            <div className="slider_area">
                <OwlCarousel className="slider_active owl-carousel" {...options}>
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`single_slider d-flex align-items-center justify-content-center area1 ${slide.bgClass}`}
                        >
                            {/* <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="slider_text text-center">
                                            <h3>{slide.title}</h3>
                                            <p>{slide.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    ))}
                </OwlCarousel>
            </div>
            {/* <!-- slider_area_end --> */}

            {/* <!-- about_area_start --> */}
            <div className="about_area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5">
                            <div className="about_info wow animate__animated animate__fadeInLeft">
                                <div className="section_title mb-20px">
                                    <span>{t('home.about_us')}</span>
                                    <h3>{t('home.luxuries_hotel')} <br />
                                        {t('home.title')}</h3>
                                </div>
                                <p>{t('home.description')}</p>
                                <a href="/about" className="line-button">{t('home.learn_more')}</a>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7">
                            <div className="about_thumb d-flex wow animate__animated animate__fadeInRight">
                                <div className="img_1 hover-scale">
                                    <img src="img/outlook1.jpg" alt="" />
                                </div>
                                <div className="img_2 hover-scale">
                                    <img src="img/outlook2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- about_area_end --> */}

            {/* <!-- video_area_start --> */}
            <div className="video_area video_bg overlay wow animate__animated animate__zoomIn">
                <div className="video_area_inner text-center">
                    <span>{t('home.video_montana_sea_view')}</span>
                    <h3>{t('home.video_relax_vacation')}</h3>
                    <a href="https://www.youtube.com/watch?v=4Es7tNlN5yQ" className="video_btn popup-video">
                        <i className="fa fa-play"></i>
                    </a>
                </div>
            </div>
            {/* <!-- video_area_end --> */}

            {/* <!-- about_area_start --> */}
            <div className="about_area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7">
                            <div className="about_thumb2 d-flex wow animate__animated animate__fadeInLeft">
                                <div className="img_1 hover-scale">
                                    <img src="img/food2.jpg" alt="" />
                                </div>
                                <div className="img_2 hover-scale">
                                    <img src="img/food3.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <div className="about_info wow animate__animated animate__fadeInRight">
                                <div className="section_title mb-20px">
                                    <span>{t('home.delicious_food')}</span>
                                    <h3>{t('home.serve_food_title_line1')} <br />
                                        {t('home.serve_food_title_line2')}</h3>
                                </div>
                                <p>{t('home.serve_food_description')}</p>
                                <a href="/about" className="line-button">{t('home.learn_more')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_area_end --> */}

            {/* <!-- offers_area_start --> */}
            {/* <div className="offers_area padding_top wow animate__animated animate__fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section_title text-center mb-100">
                                <span>{t('home.our_offers')}</span>
                                <h3>{t('home.ongoing_offers')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div className="single_offers wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
                                <div className="about_thumb">
                                    <img src="img/neptune2.jpg" alt="" />
                                </div>
                                <h3>{t('home.offer_title')}</h3>
                                <ul>
                                    <li>{t('home.luxaries_condition')}</li>
                                    <li>{t('home.room_size')}</li>
                                    <li>{t('home.sea_view')}</li>
                                </ul>
                                <a href="#" className="book_now">{t('home.book_now')}</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="single_offers wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                                <div className="about_thumb">
                                    <img src="img/neputne19.jpg" alt="" />
                                </div>
                                <h3>{t('home.offer_title')}</h3>
                                <ul>
                                    <li>{t('home.luxaries_condition')}</li>
                                    <li>{t('home.room_size')}</li>
                                    <li>{t('home.sea_view')}</li>
                                </ul>
                                <a href="#" className="book_now">{t('home.book_now')}</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="single_offers wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                                <div className="about_thumb">
                                    <img src="img/neptune-bay-hotel.jpg" alt="" />
                                </div>
                                <h3>{t('home.offer_title')}</h3>
                                <ul>
                                    <li>{t('home.luxaries_condition')}</li>
                                    <li>{t('home.room_size')}</li>
                                    <li>{t('home.sea_view')}</li>
                                </ul>
                                <a href="#" className="book_now">{t('home.book_now')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- offers_area_end --> */}

            {/* <!-- forQuery_start --> */}
            <div className="forQuery">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-md-12 wow animate__animated animate__zoomIn">
                            <div className="Query_border">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-xl-6 col-md-6">
                                        <div className="Query_text">
                                            <p>{t('home.for_query')}</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6">
                                        <div className="phone_num">
                                            <a href="/contact" className="mobile_no">+94 (0) 91 2234014</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- forQuery_end--> */}

            {/* <!-- instragram_area_start --> */}
            <div className="instragram_area">
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.1s">
                    <img src="img/insta1 (1).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.2s">
                    <img src="img/insta1 (3).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.3s">
                    <img src="img/insta1 (2).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.4s">
                    <img src="img/inst.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.5s">
                    <img src="img/in1.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            {/* <!-- instragram_area_end --> */}

        </>
    )
}

export default Home;