import { useTranslation } from 'react-i18next';

const Blog = () => {

    const { t } = useTranslation();

    return (
        <>
            {/* <!-- bradcam_area_start --> */}
            <div class="bradcam_area breadcam_bg">
                <h3>{t('blog.title')}</h3>
            </div>
            {/* <!-- bradcam_area_end --> */}


            {/* <!--================Blog Area =================--> */}
            <section class="blog_area section-padding">
                <div class="container">
                    <div class="row1">
                        <div class="col-lg-8 mb-5 mb-lg-0">
                            <div class="blog_left_sidebar">
                                <article class="blog_item wow animate__animated animate__zoomIn">
                                    <div class="blog_item_img">
                                        <img class="card-img rounded-0" src="img/galle-fort.jpg" alt="" />
                                        <a href="#" class="blog_item_date">
                                            <h3>15</h3>
                                            <p>Jan</p>
                                        </a>
                                    </div>

                                    <div class="blog_details">
                                        <a class="d-inline-block" href="single-blog.html">
                                            <h2>{t('experience.heading1')}</h2>
                                        </a>
                                        <p>{t('experience.desc1')}</p>
                                        <ul class="blog-info-link">
                                            <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>

                                <article class="blog_item wow animate__animated animate__zoomIn">
                                    <div class="blog_item_img">
                                        <img class="card-img rounded-0" src="img/diving.jpg" alt="" />
                                        <a href="#" class="blog_item_date">
                                            <h3>03</h3>
                                            <p>Nov</p>
                                        </a>
                                    </div>

                                    <div class="blog_details">
                                        <a class="d-inline-block" href="single-blog.html">
                                            <h2>{t('experience.heading2')}</h2>
                                        </a>
                                        <p>{t('experience.desc2')}</p>
                                        <ul class="blog-info-link">
                                            <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>

                                <article class="blog_item wow animate__animated animate__zoomIn">
                                    <div class="blog_item_img">
                                        <img class="card-img rounded-0" src="img/surfing.jpg" alt="" />
                                        <a href="#" class="blog_item_date">
                                            <h3>20</h3>
                                            <p>June</p>
                                        </a>
                                    </div>

                                    <div class="blog_details">
                                        <a class="d-inline-block" href="single-blog.html">
                                            <h2>{t('experience.heading3')}</h2>
                                        </a>
                                        <p>{t('experience.desc3')}</p>
                                        <ul class="blog-info-link">
                                            <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>

                                <article class="blog_item wow animate__animated animate__zoomIn">
                                    <div class="blog_item_img">
                                        <img class="card-img rounded-0" src="img/beach.jpg" alt="" />
                                        <a href="#" class="blog_item_date">
                                            <h3>25</h3>
                                            <p>Sep</p>
                                        </a>
                                    </div>

                                    <div class="blog_details">
                                        <a class="d-inline-block" href="single-blog.html">
                                            <h2>{t('experience.heading6')}</h2>
                                        </a>
                                        <p>{t('experience.desc6')}</p>
                                        <ul class="blog-info-link">
                                            <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>

                                <article class="blog_item wow animate__animated animate__zoomIn">
                                    <div class="blog_item_img">
                                        <img class="card-img rounded-0" src="img/turtle.jpg" alt="" />
                                        <a href="#" class="blog_item_date">
                                            <h3>11</h3>
                                            <p>Oct</p>
                                        </a>
                                    </div>

                                    <div class="blog_details">
                                        <a class="d-inline-block" href="single-blog.html">
                                            <h2>{t('experience.heading5')}</h2>
                                        </a>
                                        <p>{t('experience.desc5')}</p>
                                        <ul class="blog-info-link">
                                            <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                        </ul>
                                    </div>
                                </article>

                                <nav class="blog-pagination justify-content-center d-flex">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Previous">
                                                <i class="ti-angle-left"></i>
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link">1</a>
                                        </li>
                                        <li class="page-item active">
                                            <a href="#" class="page-link">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="#" class="page-link" aria-label="Next">
                                                <i class="ti-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog_right_sidebar">
                                

                               

                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================Blog Area =================--> */}
        </>
    )
}

export default Blog;