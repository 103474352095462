import React from 'react';
import { useTranslation } from 'react-i18next';

const Room = () => {

    const { t } = useTranslation();

    return (
        <>
            {/* <!-- bradcam_area_start --> */}
            <div class="bradcam_area breadcam_bg_1">
                <h3>{t('room.luxary_rooms')}</h3>
            </div>
            {/* <!-- bradcam_area_end --> */}

            {/* <!-- about_main_info_start --> */}
            <div class="about_main_info wow animate__animated animate__fadeInDown">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-md-12" style={{ textAlign: 'center' }}>
                            <div class="single_about_info">
                                <h3>{t('room.acco')}</h3>
                                <p>{t('room.acco_des')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_main_info_end --> */}

            {/* <!-- features_room_startt --> */}
            <div class="features_room">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="section_title text-center mb-100 wow animate__animated animate__fadeInUp">
                                <span>{t('room.featured_rooms')}</span>
                                <h3>{t('room.choose_better_room')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rooms_here">
                    <div class="single_rooms">
                        <div class="room_thumb">
                            <img src="img/neptune9.jpg" alt="" />
                            <div class="room_heading d-flex justify-content-between align-items-center">
                                <div class="room_heading_inner">
                                    <span>{t('room.from_price')}</span>
                                    <h3>{t('room.superior_room')}</h3>
                                </div>
                                <a href="#" className="line-button">{t('room.book_now')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="single_rooms">
                        <div class="room_thumb">
                            <img src="img/neprm3.jpg" alt="" />
                            <div class="room_heading d-flex justify-content-between align-items-center">
                                <div class="room_heading_inner">
                                    <span>{t('room.from_price')}</span>
                                    <h3>{t('room.deluxe_room')}</h3>
                                </div>
                                <a href="#" className="line-button">{t('room.book_now')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="single_rooms">
                        <div class="room_thumb">
                            <img src="img/neprm1.jpg" alt="" />
                            <div class="room_heading d-flex justify-content-between align-items-center">
                                <div class="room_heading_inner">
                                    <span>{t('room.from_price')}</span>
                                    <h3>{t('room.signature_room')}</h3>
                                </div>
                                <a href="#" className="line-button">{t('room.book_now')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="single_rooms">
                        <div class="room_thumb">
                            <img src="img/neprm2.jpg" alt="" />
                            <div class="room_heading d-flex justify-content-between align-items-center">
                                <div class="room_heading_inner">
                                    <span>{t('room.from_price')}</span>
                                    <h3>{t('room.couple_room')}</h3>
                                </div>
                                <a href="#" className="line-button">{t('room.book_now')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- features_room_end --> */}

            {/* <!-- about_main_info_start --> */}
            <div class="about_main_info">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-md-12">
                            <div class="single_about_info wow animate__animated animate__fadeInLeft" style={{ textAlign: 'center' }}>
                                <h3>{t('room.facilities')}</h3>
                                <p>{t('room.facil_des')}</p>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12" style={{ display: 'flex', justifyContent: 'center', marginBottom: '-90px' }}>
                            <div class="single_about_info wow animate__animated animate__fadeInRight features">
                                <h3>{t('room.amenities')}</h3>
                                <li>{t('room.list1')}</li>
                                <li>{t('room.list2')}</li>
                                <li>{t('room.list3')}</li>
                                <li>{t('room.list4')}</li>
                                <li>{t('room.list5')}</li>
                                <li>{t('room.list6')}</li>
                                <li>{t('room.list7')}</li>
                                <li>{t('room.list8')}</li>
                                <li>{t('room.list9')}</li>
                                <li>{t('room.list10')}</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_main_info_end --> */}

            {/* <!-- forQuery_start --> */}
            <div class="forQuery ">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1 col-md-12 wow animate__animated animate__zoomIn">
                            <div class="Query_border" style={{ marginTop: "40px" }}>
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-xl-6 col-md-6">
                                        <div class="Query_text">
                                            <p>{t('room.reservation_query')}</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6">
                                        <div class="phone_num">
                                            <a href="/contact" class="mobile_no">+94 (0) 91 2234014</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- forQuery_end--> */}

            {/* <!-- instragram_area_start --> */}
            <div className="instragram_area">
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.1s">
                    <img src="img/insta1 (1).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.2s">
                    <img src="img/insta1 (3).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.3s">
                    <img src="img/insta1 (2).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.4s">
                    <img src="img/inst.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.5s">
                    <img src="img/in1.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            {/* <!-- instragram_area_end --> */}
        </>
    )
}

export default Room;