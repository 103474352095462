import React from "react";

const EnglishDrink = () => {

    return (
        <div className="menu-container">
            <header className="menu-header">
                <div className="menu-div">
                    <a href="/">
                        <img className="logo" src="img/nb.png" alt="Logo" />
                    </a>
                </div>
                <h1>Menu</h1>
            </header>

            <div className="menu-sections">
                {/* Classic Cocktails Section */}
                <div className="menu-section">
                    <h2 className="english">Classic Cocktails</h2>
                    <div className="menu-item">
                        <span>Old Fashion</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Bourbon Whiskey, Sugar Cube & Dashes Angostura Bitters
                    </p>

                    <div className="menu-item">
                        <span>Negroni</span>
                        <span>Rs 3800/=</span>
                    </div>
                    <p className="menu-des">
                        Gin, Martini Rosso & Campari
                    </p>

                    <div className="menu-item">
                        <span>Dry Martini</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Gin & Dry Vermouth
                    </p>

                    <div className="menu-item">
                        <span>Singapore Sling</span>
                        <span>Rs 3500/=</span>
                    </div>
                    <p className="menu-des">
                        Gin, Orange Liqueur, Cherry Brandy, Pineapple Juice, Lime & Soda
                    </p>

                    <div className="menu-item">
                        <span>Cube Libra</span>
                        <span>Rs 2500/=</span>
                    </div>
                    <p className="menu-des">
                        White Rum, Lime Juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>Frozen Daiquiri</span>
                        <span>Rs 3500/=</span>
                    </div>
                    <p className="menu-des">
                        White Rum, Lime Juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>Flaming Lamborghini</span>
                        <span>Rs 5000/=</span>
                    </div>
                    <p className="menu-des">
                        Bailys “Coffee Liqueur, Sambuca, Blue Curacao
                    </p>

                    <div className="menu-item">
                        <span>Margarita</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Tequila, Triple Sec, Lime Juice
                    </p>

                    <div className="menu-item">
                        <span>Long Island Iced Tea</span>
                        <span>Rs 4000/=</span>
                    </div>
                    <p className="menu-des">
                        Rum, Vodka, Gin, Tequila, Triple Sec, Lime Juice, Top up with Cola
                    </p>

                    <div className="menu-item">
                        <span>Pinacolada</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        White Rum, Mallimbu, Coconut Cream, Pineapple Juice
                    </p>

                    <div className="menu-item">
                        <span>Bull Frog</span>
                        <span>Rs 4500/=</span>
                    </div>
                    <p className="menu-des">
                        Vodka, Gin, Rum, Tequila, Blue Curacao, Lime Juice, Top up with Red Bull
                    </p>

                    <div className="menu-item">
                        <span>Tequila Sunrice</span>
                        <span>Rs 3500/=</span>
                    </div>
                    <p className="menu-des">
                        Tequila, Orange Juice, Grenadine Syrup
                    </p>

                    <div className="menu-item">
                        <span>Godfather</span>
                        <span>Rs 3500/=</span>
                    </div>
                    <p className="menu-des">
                        Scotch Whiskey & Amereto
                    </p>

                    <div className="menu-item">
                        <span>Mojito</span>
                        <span>Rs 3500/=</span>
                    </div>
                    <p className="menu-des">
                        White Rum, Mint Leaves, Lime Juice, Sugar, Top up Soda, (Flavors in Choices)
                    </p>

                    <div className="menu-item">
                        <span>Aperol Spritz</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Sparkline Wine, Aperol & Top Up Soda
                    </p>
                </div>

                {/* Signature Cocktails Section */}
                <div className="menu-section">
                    <h2 className="english">Signature Cocktails</h2>
                    <div className="menu-item">
                        <span>Arrack Passion</span>
                        <span>Rs 2500/=</span>
                    </div>
                    <p className="menu-des">
                        Old Arrack, Fresh Passion Fruit, Lime Juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>Arrack Shower</span>
                        <span>Rs 2800/=</span>
                    </div>
                    <p className="menu-des">
                        Arrack, Egg White, Lime Juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>The Bertie</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Gin, Elderflower Syrup, Mint Leaves, Lime Juice
                    </p>

                    <div className="menu-item">
                        <span>Basil Mashz</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Gin, Fresh Bassil, Lime Juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>Pink Panther</span>
                        <span>Rs 3000/=</span>
                    </div>
                    <p className="menu-des">
                        Vodka, Orange Juice, Watermelon, Lime juice, Sugar Syrup
                    </p>

                    <div className="menu-item">
                        <span>Siyabalaness</span>
                        <span>Rs 2800/=</span>
                    </div>
                    <p className="menu-des">
                        Red Rum, Cherry Brandy, Tamarind, Passion Juice
                    </p>
                </div>
            </div>

            <div className="menu-sections">
                {/* Mocktails Section */}
                <div className="menu-section">
                    <h2 className="english">Mocktails</h2>
                    <div className="menu-item">
                        <span>Ginger Luchy Lemonade</span>
                        <span>Rs 2500/=</span>
                    </div>
                    <p className="menu-des">
                        Fresh Ginger, Luchy, Lime Juice, Top up Lemonade
                    </p>

                    <div className="menu-item">
                        <span>D Moulay</span>
                        <span>Rs 2000/=</span>
                    </div>
                    <p className="menu-des">
                        Lemon Grass, Lime Juice, Sugar Syrup, Top up Soda
                    </p>

                    <div className="menu-item">
                        <span>Kiwi & Cucumber</span>
                        <span>Rs 2000/=</span>
                    </div>
                    <p className="menu-des">
                        Cucumber, Kiwi Syrup, Lime juice, Top up Soda
                    </p>

                    <div className="menu-item">
                        <span>Tutti Fruity Soda</span>
                        <span>Rs 2000/=</span>
                    </div>
                    <p className="menu-des">
                        Cranberry Juice, Ginger, Orange Juice, Soda Top Up
                    </p>

                    <div className="menu-item">
                        <span>Virgin Mojito</span>
                        <span>Rs 2000/=</span>
                    </div>
                    <p className="menu-des">
                        Mint Leaves, Lime Juice, Sugar Syrup, Top up Sprite (Flavors in Choices)
                    </p>

                    <div className="menu-item">
                        <span>Virgin Pinacolada</span>
                        <span>Rs 2000/=</span>
                    </div>
                    <p className="menu-des">
                        Pineapple Juice, Coconut Syrup
                    </p>
                </div>

                {/* Beer Section */}
                <div className="menu-section">
                    <h2 className="english">Beer</h2>
                    <div className="menu-item">
                        <span>Lion Larger 625ml</span>
                        <span>Rs 1100/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Lion Larger 500ml</span>
                        <span>Rs 1000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Carlsberg 500ml</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Heineken 500ml</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Lion Ice 330ml</span>
                        <span>Rs 800/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Tiger 550ml</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Somersby Apple</span>
                        <span>Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Somersby Blackberry</span>
                        <span>Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>
            </div>

            <div className="menu-sections">
                {/* Soft Drinks Section */}
                <div className="menu-section">
                    <h2 className="english">Soft Drinks</h2>
                    <div className="menu-item">
                        <span>Red bull</span>
                        <span>Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Diet Cola</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Coca Cola</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Sprite</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Fanta</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Tonic</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Ginger Ale</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Ginger Beer</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Soda</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>

                {/* Fresh Juices Section */}
                <div className="menu-section">
                    <h2 className="english">Fresh Juices</h2>
                    <div className="menu-item">
                        <span>Pineapple</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Mango</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Papaya </span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Watermelon</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Mixed Fruit</span>
                        <span>Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Fresh Lime</span>
                        <span>Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Avocado Juice</span>
                        <span>Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>King Coconut</span>
                        <span>Rs 500/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>
            </div>

            <div className="menu-sections">
                {/* Milkshake Section */}
                <div className="menu-section">
                    <h2 className="english">Milkshake</h2>
                    <div className="menu-item">
                        <span>Banana Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Mango Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Strawberry Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Chocolate Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Pineapple Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Vanilla Milkshake</span>
                        <span>Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>

                {/* White Wine Section */}
                <div className="menu-section">
                    <h2 className="english">White Wine</h2>
                    <div className="menu-item">
                        <span>El zorro blanco</span>
                        <span>Glass - Rs 1500/=, Bottle - Rs 7500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Banrock Station Chardoney</span>
                        <span>Glass - Rs 2800/=, Bottle - Rs 14000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>The Beach House Sauvignon</span>
                        <span>Glass - Rs 3000/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Tall Horse Chardoney</span>
                        <span>Glass - Rs 3000/=, Bottle - Rs 12000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Con Sur Tocomal Survignin Blanc</span>
                        <span>Glass - Rs 2800/=, Bottle - Rs 14000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Con sur Tocomal Chardoney</span>
                        <span>Glass - Rs 2800/=, Bottle - Rs 14000/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>
            </div>

            <div className="menu-sections">
                {/* Red Wine Section */}
                <div className="menu-section">
                    <h2 className="english">Red Wine</h2>
                    <div className="menu-item">
                        <span>El zoro el rojo</span>
                        <span>Glass - Rs 2400/=, Bottle - Rs 12000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Tall Horse Pintoger Rose</span>
                        <span>Glass - Rs 3000/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Cono Sur Toconal Merlon</span>
                        <span>Glass - Rs 2800/=, Bottle - Rs 14500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Cono sur Toconal Carbernet Sauvignon</span>
                        <span>Glass - Rs 3000/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>

                {/* Local Spirits Section */}
                <div className="menu-section">
                    <h2 className="english">Local Spirits</h2>
                    <div className="menu-item">
                        <span>Vodka</span>
                        <span>50ml - Rs 1200/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Gin</span>
                        <span>50ml - Rs 1600/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>White Rum</span>
                        <span>50ml - Rs 1500/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Dark Rum</span>
                        <span>50ml - Rs 1500/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Tequila</span>
                        <span>50ml - Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Old Arrack</span>
                        <span>50ml - Rs 1200/=, Bottle - Rs 10000/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Ritz Brandy</span>
                        <span>50ml - Rs 1500/=, Bottle - Rs 15000/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>
            </div>

            <div className="menu-sections">
                {/* Foreign Spirits Section */}
                <div className="menu-section">
                    <h2 className="english">Foreign Spirits</h2>
                    <div className="menu-item">
                        <span>Jack Daniel</span>
                        <span>25 ml - Rs 1500/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Black Lable	</span>
                        <span>25 ml - Rs 1700/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Vat 69</span>
                        <span>25 ml - Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Jesom</span>
                        <span>25 ml - Rs 1200/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Hendriks</span>
                        <span>25 ml - Rs 1800/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Bombo</span>
                        <span>25 ml - Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Absolute</span>
                        <span>25 ml - Rs 1600/=</span>
                    </div>
                    <p className="menu-des"></p>

                    <div className="menu-item">
                        <span>Sky Vodka</span>
                        <span>25 ml - Rs 1700/=</span>
                    </div>
                    <p className="menu-des"></p>
                </div>
            </div>
        </div>
    );
};

export default EnglishDrink;
