import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RussianMenu = () => {
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();

  const handleMenuChoice = (type) => {
    if (type === "Food") {
      navigate("/qr-menu-russian-food");
    } else if (type === "Drink") {
      navigate("/qr-menu-russian-drink");
    }
    setShowPopup(false); // Hide popup after selection
  };

  return (
    <div className="menu-wrapper" style={wrapperStyles}>
      <div className="menu-background" style={backgroundStyles}></div>

      {showPopup && (
        <div className="popup" style={popupStyles}>
          <h3
            style={{
              color: "white",
              fontFamily: '"Dancing Script", cursive',
              fontSize: "30px",
            }}
          >
            Выберите тип меню
          </h3>
          <div
            className="button-group1"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "25%",
            }}
          >
            <button
              className="button1 btn-popup"
              style={{
                borderRadius: "8px",
                padding: "8px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
              onClick={() => handleMenuChoice("Food")}
            >
              Еда 🍔
            </button>
            <button
              className="button1 btn-popup"
              style={{ borderRadius: "8px", padding: "7px" }}
              onClick={() => handleMenuChoice("Drink")}
            >
              Напиток 🍹
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Wrapper styles (full-screen container)
const wrapperStyles = {
  height: "100vh",
  position: "relative",
};

// Background styles with blur effect
const backgroundStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: 'url("img/banner/banner.png")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  filter: "blur(8px)",
  zIndex: 1, // Layer behind the popup
};

// Popup styles (no blur effect)
const popupStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgb(66, 55, 55)",
  padding: "60px",
  borderRadius: "20px",
  zIndex: 2, // Layer above the background
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  fontFamily: '"Permanent Marker", cursive',
  fontWeight: 700,
  fontStyle: "normal",
};

export default RussianMenu;
