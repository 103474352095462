import React from "react";

const EnglishFood = () => {

  return (
    <div className="menu-container">
      <header className="menu-header">
        <div className="menu-div">
          <a href="/">
            <img className="logo" src="img/nb.png" alt="Logo" />
          </a>
        </div>
        <h1>A LA CARTE</h1>
      </header>

      <div className="menu-sections">
        {/* appetizer Section */}
        <div className="menu-section">
          <h2 className="english">Appetizer</h2>
          <div className="menu-item">
            <span>Falafel</span>
            <span>Rs 2000/=</span>
          </div>
          <p className="menu-des">
            Fried Ground chickpeas, parsley, coriander, garlic, cumin, lime
            served with Tahini sauce
          </p>

          <div className="menu-item">
            <span>Tuna Tataki</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Sushi-grade tuna, fresh avocado salsa, sesame, served With Sesame &
            Soy sauce
          </p>

          <div className="menu-item">
            <span>Three beans salad</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Three types of beans (chickpeas, celery, kidney beans & cannellini
            beans) mixed with herbs dressing.
          </p>

          <div className="menu-item">
            <span>Shrimp & avocado salad</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Shrimp, lettuce, onion, avocado mixed with chopped parsley served
            with olive oil & lime Dressing
          </p>

          <div className="menu-item">
            <span>Thai Beef Salad (spicy)</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Pan-seared beef Served with, Red Onion, Cucumber, Tomato, coriander
            leaves, Fresh Mint, tomato, chili, sugar, peanuts and soya sauce
          </p>

          <div className="menu-item">
            <span>Tom yum soup (Thai hot and sour soup)</span>
            <span>Rs 2200/=</span>
          </div>
          <p className="menu-des">
            Prawns with lemon grass, coconut milk, lime leaves, ginger, shrimp
            paste, sugar, fish sauce, lime juice and tomato puree
          </p>

          <div className="menu-item">
            <span>Cream of mushroom Soup</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            Cooking mushroom along with butter, garlic, onion, flour in a milk
            based creamy sauce
          </p>
        </div>

        {/* c Section */}
        <div className="menu-section">
          <h2 className="english">Main Dish</h2>
          <div className="menu-item">
            <span>Surf and Turf</span>
            <span>Rs 5500/=</span>
          </div>
          <p className="menu-des">
            Pan grilled beef steak and Shrimps, Mashed potatoes, garlic and
            onion Sautéed fresh upcountry vegetables served with garlic creamy
            sauce
          </p>

          <div className="menu-item">
            <span>Chicken Frances</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Egg and flour coated pan sautéed chicken cooked with white wine,
            lemon and parsley sauce served with garlic and onion sautéed fresh
            upcountry vegetables and mashed potatoes
          </p>

          <div className="menu-item">
            <span>Risotto with mushroom & chicken</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Short grain rice, mushrooms, chicken, white wine, fresh herbs and
            Parmesan cheese
          </p>

          <div className="menu-item">
            <span>Frutti di mare</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Spaghetti with baby clams, octopus , calamari and prawns cooked in
            red homemade arrabiata sauce served with parmesan cheese
          </p>

          <div className="menu-item">
            <span>Indian ocean fresh yellow fin Tuna Steak</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Fresh grilled tuna fillet served with Herb seasoning baby potatoes
            and fresh homemade salad
          </p>

          <div className="menu-item">
            <span>Classic British fish and Chips</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            Batter fried fresh snapper fish fillet served with home made
            potatoes fries and home made tartare sauce
          </p>

          <div className="menu-item">
            <span>Creamy Garlic Pork chop</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Marinated pork chop cooked in creamy garlic parmesan sauce served
            with Garlic and basil infused white rice or creamy mushrooms
            spaghetti
          </p>

          <div className="menu-item">
            <span>Grilled lamb chop</span>
            <span>Rs 6500/=</span>
          </div>
          <p className="menu-des">
            Tender marinated lamb chop cooked on the grill and tossed in mint
            sauce. served with mashed potatoes with garlic and onion sautéed
            fresh upcountry vegetables
          </p>

          <div className="menu-item">
            <span>Spinach & ricotta ravioli</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Spinach and ricotta stuffed ravioli sautéed with onions and Italian
            herbs gently simmered in traditional marinara or creamy sauce
          </p>

          <div className="menu-item">
            <span>Triple Decker</span>
            <span>Rs 3200/=</span>
          </div>
          <p className="menu-des">
            Triple the taste with our srilankan beef, onions, pickles, tomato,
            iceberg lettuce, ketchup, mustard and cheddar cheese, All in a
            homemade soft toasted bun, served with homemade potatoes fries
          </p>

          <div className="menu-item">
            <span>Crispy chicken Burger</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Well seasoning boneless crispy chicken served on a toasted bun with
            jalapeños and homemade honey mustard coleslaw served with homemade
            potatoes fries
          </p>

          <div className="menu-item">
            <span>Indian Ocean fresh Grilled seer fish</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Fresh grilled seer fish filet, baby potatoes, fresh garden salad
            served with garlic cream sauce or lemon capes sauce.
          </p>

          <div className="menu-item">
            <span>Couscous with chicken & vegetables</span>
            <span>Rs 2400/=</span>
          </div>
          <p className="menu-des">
            Stir fry couscous with fresh upcountry veggies, chicken and eggs
          </p>

          <div className="menu-item">
            <span>Papa Della pasta with chicken & mushroom Alf redo sauce</span>
            <span>Rs 3400/=</span>
          </div>
          <p className="menu-des">
            Sautéed chicken breast and mushrooms served in Alfredo sauce and
            topped with freshly chopped seasoning
          </p>

          <div className="menu-item">
            <span>Crispy Tempura Shrimps and Veggies</span>
            <span>Rs 3800/=</span>
          </div>
          <p className="menu-des">
            Freshly seasoning and battered and deep fried king prawns and fresh
            upcountry vegetables served with tempura sauce and and cherry
            tomatoes salad
          </p>

          <div className="menu-item">
            <span>
              Our Signature sizzling Fresh Indian Ocean Sea Food Platter
            </span>
            <span>Rs 35000/=</span>
          </div>
          <p className="menu-des">
            Well seasoning Fresh whole mullet fish, one baby octopus, two fresh
            jumbo prawns two fresh lobster, two fresh crabs, eight tiger prawns,
            eight calamaris ten baby clams and ten mussels (served with garlic
            and basil infused white rice with stir fry water spinach or Garlic
            and onion pan sautéed fresh upcountry Boiled Vegetables, Mashed
            potatoes served with homemade fresh salad ) (From us we will give to
            you free bottle of white or red wine bottle)
          </p>

          <div className="menu-item">
            <span>Lemon garlic calamaris</span>
            <span>Rs 3500/=</span>
          </div>
          <p className="menu-des">
            fresh calamaris seasoning with fresh lime juice, garlic, onion and
            ginger cook on the grill served with mashed potatoes and fresh
            garden salad
          </p>
        </div>
      </div>

      <div className="menu-sections">
        {/* Rice and Curry Section */}
        <div className="menu-section">
          <h2 className="english">Sri Lankan Rice and Curry</h2>
          <div className="menu-item">
            <span>Mix Vegetables curry</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            Fresh upcountry mixed vegetables cooked in coconut milk and sri
            lankan curry powders served with white rice, coconut sambol, mango
            chutney and papadam and fried chili
          </p>

          <div className="menu-item">
            <span>Fresh lagoon crab curry</span>
            <span>Rs 3600/=</span>
          </div>
          <p className="menu-des">
            Fresh koggala lake lagoon crab cooked in sri lankan spices and
            coconut milk and special curry powder from ndian served with white
            rice, coconut sambol, mamgo chutney , Bitter gourd salad, papadam
            rice or Indian style paratha (Indian flatbread)
          </p>

          <div className="menu-item">
            <span>Fresh lagoon prawn curry</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Fresh koggala lake king prawns cooked in sri lankan spices and
            coconut milk and curry powder served with white rice, coconut
            sambol,mango chutney, Bitter gourd salad, papadam rice or indian
            style paratha (flat bread)
          </p>

          <div className="menu-item">
            <span>Pineapple curry</span>
            <span>Rs 1600/=</span>
          </div>
          <p className="menu-des">
            Fresh pineapple cooked in coconut milk & mango chutney, Papadam ,
            pol sambol & Bitter gourd salad served with Rice or parata. (flat
            bread)
          </p>

          <div className="menu-item">
            <span>Octopus & coconut curry</span>
            <span>Rs 2800/=</span>
          </div>
          <p className="menu-des">
            Fresh octopus cooked in coconut milk & Sri Lankan curry powder,
            mango chutney,Papadam , pol sambol & Bitter gourd salad served with
            Rice or parata. (flat bread)
          </p>

          <div className="menu-item">
            <span>Dahl Curry (lentils)</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            Masoor dahl tempered with onion, curry leaves, cumin, and mustard
            seed curry powder, Mango chutney, papadam & Bitter gourd salad,
            served with rice, pol sambol
          </p>

          <div className="menu-item">
            <span>Prawn curry</span>
            <span>Rs 3000/=</span>
          </div>
          <p className="menu-des">
            Fresh prawns cooked in coconut milk & Sri Lankan curry powder, pol
            sambol, Mango chutney, papadam, served with rice or Parata (Indian
            flatbread)
          </p>

          <div className="menu-item">
            <span>Fish curry</span>
            <span>Rs 2500/=</span>
          </div>
          <p className="menu-des">
            Fresh fish cooked in coconut milk & Sri curry powder, pol sambol,
            Mango chutney, papadam & Bitter gourd salad served with rice or
            Parata (Indian flatbread)
          </p>

          <div className="menu-item">
            <span>*Pol Sambol</span>
            <span></span>
          </div>
          <p className="menu-des">
            Grated coconut salad with lime juice, onions, and chilies
          </p>

          <div className="menu-item">
            <span>*Papadam</span>
            <span></span>
          </div>
          <p className="menu-des">
            Crispy fried crackers made from lentil flour
          </p>

          <div className="menu-item">
            <span>*Mango chutney</span>
            <span></span>
          </div>
          <p className="menu-des">
            Containing mango, spices, sugar and vinegar
          </p>

          <div className="menu-item">
            <span>*Bitter gourd salad</span>
            <span></span>
          </div>
          <p className="menu-des">Bitter gourd, onion, tomato and lime</p>
        </div>

        {/* Side Dish Section */}
        <div className="menu-section">
          <h2 className="english">Side Dish</h2>
          <div className="menu-item">
            <span>Grilled or boiled vegetables</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Homemade Potato fries</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Steamed rice (small)</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Chapati & parata (indian flatbread)</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>

          <div className="menu-item">
            <span>Mashed potatoes</span>
            <span>Rs 1000/=</span>
          </div>
          <p className="menu-des"></p>
        </div>
      </div>

      <div className="menu-sections">
        {/* kids Section */}
        <div className="menu-section">
          <h2 className="english">Kids Menu</h2>
          <div className="menu-item">
            <span>Chicken in the basket</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des">
            Chicken drumstick, hand breaded and deep fried. Served with Potato
            fries.
          </p>

          <div className="menu-item">
            <span>Cheesy Chicken Pasta</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">Pasta with cream sauce & chicken</p>

          <div className="menu-item">
            <span>Mini cheese burger</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            Mince beef cooked in herbs served with potato fries
          </p>

          <div className="menu-item">
            <span>fish fingers</span>
            <span>Rs 1500/=</span>
          </div>
          <p className="menu-des"></p>
        </div>

        {/* dessert Section */}
        <div className="menu-section">
          <h2 className="english">Dessert</h2>
          <div className="menu-item">
            <span>Pavlova</span>
            <span>Rs 1400/=</span>
          </div>
          <p className="menu-des">
            Egg whites /vanilla extract /Sugar /cream /chocolate
          </p>

          <div className="menu-item">
            <span>Pan fried cinnamon banana</span>
            <span>Rs 1650/=</span>
          </div>
          <p className="menu-des">Banana /honey /butter /cinnamon</p>

          <div className="menu-item">
            <span>Pineapple fitters with coconut</span>
            <span>Rs 1650/=</span>
          </div>
          <p className="menu-des">Pineapple /egg /flour /coconut /milk</p>

          <div className="menu-item">
            <span>Tree kind of ice cream (create your own )</span>
            <span>Rs 1800/=</span>
          </div>
          <p className="menu-des">
            Vanilla /chocolate /strawberry /mango /fruit & nuts /butterscotch
          </p>
        </div>

        <div className="notice" style={{ textAlign: "center" }}>
          <h3></h3>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default EnglishFood;
