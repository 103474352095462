import React, { useEffect } from 'react';
import 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';
import { useTranslation } from 'react-i18next';

const About = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.$('.about_active').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            autoplay: true,
            autoplayTimeout: 5000,
            responsive: {
                0: { items: 1 },
                600: { items: 1 },
                1000: { items: 1 }
            }
        });
    }, []);

    return (
        <>
            {/* <!-- bradcam_area_start --> */}
            <div class="bradcam_area breadcam_bg_5">
                <h3>{t('about.title')}</h3>
            </div>
            {/* <!-- bradcam_area_end --> */}

            {/* <!-- about_area_start --> */}
            <div class="about_area">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-5">
                            <div class="about_info wow animate__animated animate__fadeInLeft">
                                <div class="section_title mb-20px">
                                    <span>{t('about.about_us')}</span>
                                    <h3>{t('about.luxuries_hotel')} <br />
                                        {t('about.with_nature')}</h3>
                                </div>
                                <p>{t('about.description')}</p>
                                <a href="#" className="line-button">{t('about.learn_more')}</a>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-7">
                            <div class="about_thumb d-flex wow animate__animated animate__fadeInRight">
                                <div class="img_1 hover-scale">
                                    <img src="img/outlook1.jpg" alt="" />
                                </div>
                                <div class="img_2 hover-scale">
                                    <img src="img/outlook2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_area_end --> */}

            {/* <!-- about_info_area_start --> */}
            <div class="about_info_area">
                <div class="about_active owl-carousel">
                    <div class="single_slider about_bg_1"></div>
                    <div class="single_slider about_bg_2"></div>
                    <div class="single_slider about_bg_3"></div>
                    <div class="single_slider about_bg_4"></div>
                </div>
            </div>
            {/* <!-- about_info_area_start --> */}

            {/* <!-- about_main_info_start --> */}
            <div class="about_main_info">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-md-6 wow animate__animated animate__fadeInLeft">
                            <div class="single_about_info">
                                <h3>{t('about.story_title')}</h3>
                                <p>{t('about.story_description')}</p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 wow animate__animated animate__fadeInLeft">
                            <div class="single_about_info">
                                <h3>{t('about.promise_title')}</h3>
                                <p>{t('about.promise_description')}</p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 wow animate__animated animate__fadeInRight">
                            <div class="single_about_info">
                                <h3>{t('about.loc_title')}</h3>
                                <p>{t('about.loc_description')}</p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 wow animate__animated animate__fadeInRight">
                            <div class="single_about_info">
                                <h3>{t('about.acco_title')}</h3>
                                <p>{t('about.acco_description')}</p>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 wow animate__animated animate__fadeInUp">
                            <div class="single_about_info" style={{ textAlign: 'center' }}>
                                <h3>{t('about.hury')}</h3>
                                <p>{t('about.hurry_des')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about_main_info_end --> */}

            {/* <!-- forQuery_start --> */}
            <div class="forQuery">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1 col-md-12 wow animate__animated animate__zoomIn">
                            <div class="Query_border">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-xl-6 col-md-6">
                                        <div class="Query_text">
                                            <p>{t('room.reservation_query')}</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6">
                                        <div class="phone_num">
                                            <a href="/contact" class="mobile_no">+94 (0) 91 2234014</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- forQuery_end--> */}

            {/* <!-- instragram_area_start --> */}
            <div className="instragram_area">
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.1s">
                    <img src="img/insta1 (1).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.2s">
                    <img src="img/insta1 (3).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.3s">
                    <img src="img/insta1 (2).jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.4s">
                    <img src="img/inst.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className="single_instagram wow animate__animated animate__zoomIn" data-wow-delay="0.5s">
                    <img src="img/in1.jpg" alt="" />
                    <div className="ovrelay">
                        <a href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            {/* <!-- instragram_area_end --> */}

        </>
    )
}

export default About;