import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const Contact = () => {

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_kp7b6',       // Replace with your EmailJS service ID
            'template_smkhf',      // Replace with your EmailJS template ID
            formData,
            'TbZcVEvka6Gbk0m'      // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            })
            .catch((err) => {
                console.error('FAILED...', err);
                alert('Failed to send message. Please try again later.');
            });

        setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
        });
    };

    return (
        <>
            {/* <!-- bradcam_area_start --> */}
            <div class="bradcam_area breadcam_bg_2">
                <h3>{t('contact.title')}</h3>
            </div>
            {/* <!-- bradcam_area_end --> */}

            {/* <!-- ================ contact section start ================= --> */}
            <section class="contact-section">
                <div class="container">

                    <div class="row">
                        <div class="col-12 wow animate__animated animate__fadeInLeft">
                            <h2 className="contact-title">{t('contact.get_in_touch')}</h2>
                        </div>
                        <div class="col-lg-8 wow animate__animated animate__fadeInLeft">
                            <form className="form-contact contact_form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input className="form-control valid" name="name" id="name" type="text" placeholder={t('contact.enter_name')} value={formData.name} onChange={handleChange} required/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input className="form-control valid" name="email" id="email" type="email" placeholder={t('contact.enter_email')} value={formData.email} onChange={handleChange} required/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" name="subject" id="subject" type="text" placeholder={t('contact.enter_subject')} value={formData.subject} onChange={handleChange} required/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" placeholder={t('contact.enter_message')} value={formData.message} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <button type="submit" className="button button-contactForm boxed-btn">{t('contact.send')}</button>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-3 offset-lg-1 wow animate__animated animate__fadeInRight">
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-home"></i></span>
                                <div class="media-body">
                                    <h3>{t('contact.address_title')}</h3>
                                    <p>{t('contact.address')}</p>
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                <div class="media-body">
                                    <h3>+94 (0) 91 2234014</h3>
                                    <p>{t('contact.phone_availability')}</p>
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-email"></i></span>
                                <div class="media-body">
                                    <h3>{t('contact.email')}</h3>
                                    <p>{t('contact.email_note')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container wow animate__animated animate__zoomIn">
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d126972.52055510899!2d80.16427555035467!3d6.009665530351613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3ae172ee57eaa1d3%3A0xb70974ef82b92cac!2sUnawatuna!3m2!1d6.0096717!2d80.24667749999999!5e0!3m2!1sen!2slk!4v1725597779327!5m2!1sen!2slk"
                            height="400px" width="100%" style={{ border: 0 }} allowFullScreen=""></iframe>
                    </div>


                </div>
            </section>
            {/* <!-- ================ contact section end ================= --> */}
        </>
    )
}

export default Contact;